<template>
  <div class="px-8 pb-6">
    <trac-custom-header class="mt-5">
      <template slot="leading">New</template>
      <template slot="trailing"> Ticket</template>
    </trac-custom-header>
    <trac-input
      v-model="ticketName"
      placeholder="Ticket Name"
      class="mt-10"
    ></trac-input>
    <div class="flex flex-row justify-end mt-5">
      <trac-button
        :disabled="ticketName === ''"
        @button-clicked="$emit('create-ticket', ticketName)"
        class="uppercase"
        >Add</trac-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "NewTicket",
  props: ["saveTicketName"],
  watch: {
    saveTicketName(x) {
      if (x) {
        this.ticketName = x;
      }
    },
  },
  data() {
    return {
      ticketName: "",
    };
  },
  mounted() {
    this.ticketName = this.saveTicketName;
  }
};
</script>

<style scoped></style>
