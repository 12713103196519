<template>
  <div>
    <div class="mt-2">
      <h1 class="uppercase font-medium">
        Tickets
      </h1>
      <svg
        width="20"
        height="4"
        viewBox="0 0 20 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 2C0 0.895431 0.895431 0 2 0H18C19.1046 0 20 0.895431 20 2C20 3.10457 19.1046 4 18 4H2C0.895431 4 0 3.10457 0 2Z"
          fill="url(#paint0_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="-9.46895"
            y1="2.21242"
            x2="-7.84897"
            y2="9.88217"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#06B6CB" />
            <stop offset="1" stop-color="#253B95" />
          </linearGradient>
        </defs>
      </svg>
    </div>
    <!--        <div class="flex flex-row justify-end mt-10">-->
      <div class="h-56" v-if="tickets.length === 0">
        <trac-center-data>
          <span class="text-gray-600">No saved tickets.</span>
        </trac-center-data>
      </div>
    <div
    v-else
      class="mt-10 border bg-white rounded-lg overflow-auto "
      style="height: 50vh"
    >
      <table class=" w-full table-auto overflow-auto">
        <thead class="bg-blue-100">
          <tr class="h-20">
            <th class="text-left pl-8 text-xs font-semibold uppercase">No.</th>
            <th class="text-left text-xs font-semibold uppercase">
              Ticket Name
            </th>
            <th class="text-left text-xs font-semibold uppercase">
              Unit Price
            </th>
            <th class="text-left text-xs font-semibold uppercase">Item</th>
            <th class="text-left text-xs font-semibold uppercase">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr
            :class="index % 2 === 0 ? '' : 'bg-gray-100'"
            v-for="(ticket, index) in tickets"
            class="h-16 hover:bg-gray-200 cursor-pointer"
            :key="index"
            @click="$emit('select-ticket', ticket)"
          >
            <td class="text-xs pl-8">
              {{ index + 1 }}
            </td>
            <td class="text-sm font-bold capitalize">
              {{ ticket.ticket_name }}
            </td>
            <td class="text-xs font-medium">
              {{ moment(ticket.update_at).format('Do MMM YYYY') }}
            </td>
            <td class="text-xs font-medium capitalize">
              {{ ticket.items.length }}
            </td>
            <td class="text-xs font-medium capitalize">{{ ticket.total_price | formatPrice }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--        </div>-->
  </div>
</template>

<script>
  import moment from 'moment';
export default {
  name: "OpenTicket",
  props: ['tickets'],
  data() {
    return {
      moment,
      // tickets: []
    };
  },
};
</script>

<style scoped></style>
