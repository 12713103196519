<template>
  <main>
    <p class="flex justify-center py-4 text-lg font-bold">
      {{ amount | formatPrice }}
    </p>
    <div class="grid grid-cols-3 my-4">
      <button class="p-4 hover:bg-gray-400" @click="enterNumber(1)">1</button>
      <button class="p-4 hover:bg-gray-400" @click="enterNumber(2)">2</button>
      <button class="p-4 hover:bg-gray-400" @click="enterNumber(3)">3</button>
      <button class="p-4 hover:bg-gray-400" @click="enterNumber(4)">4</button>
      <button class="p-4 hover:bg-gray-400" @click="enterNumber(5)">5</button>
      <button class="p-4 hover:bg-gray-400" @click="enterNumber(6)">6</button>
      <button class="p-4 hover:bg-gray-400" @click="enterNumber(7)">7</button>
      <button class="p-4 hover:bg-gray-400" @click="enterNumber(8)">8</button>
      <button class="p-4 hover:bg-gray-400" @click="enterNumber(9)">9</button>
      <!-- :class="+amount > 0 && sumCounts > 0 ? 'bg-green-400 hover:bg-green-400 text-white' : ''" -->
      <button class="p-4 hover:bg-gray-400" @click="addNumber">+</button>
      <button class="p-4 hover:bg-gray-400" @click="enterNumber(0)">0</button>
      <button
        class="p-4 hover:bg-gray-400 flex justify-center items-center"
        @click="deleteNumber"
      >
        <img
          src="./../../assets/svg/backspace.svg"
          width="15"
          alt=""
          srcset=""
        />
      </button>
    </div>
    <trac-button
      :disabled="sumCounts === 0"
      @button-clicked="chargeAmount"
      class="block w-full"
      >CHARGE {{ sumCounts | formatPrice }}</trac-button
    >
  </main>
</template>

<script>
import { GENERATE_RANDOM_ID } from "../../browser-db-config/utilityMethods";
export default {
  data() {
    return {
      amount: "0",
      itemCount: 0,
      counts: [],
    };
  },
  computed: {
    sumCounts() {
      return this.counts.length === 0
        ? +this.amount
        : this.counts.reduce((tot, count) => tot + count, 0);
    },
  },
  methods: {
    enterNumber(number) {
      this.amount += number;
    },
    addNumber() {
      this.itemCount += 1;
      this.counts.push(+this.amount);
      this.amount = "0";
    },
    makeid(length = 24) {
      var result = "";
      var characters = "abcdef0123456789";
      var charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return "" + result;
    },
    deleteNumber() {
      this.amount = this.amount.substring(0, this.amount.length - 1);
    },
    chargeAmount() {
      this.$emit("charge-amount", {
        _id: this.makeid(),
        title: "Quick Sale Items",
        quickSaleTotal:
          this.counts.length === 0 ? +this.amount : this.sumCounts,
        count: this.itemCount || 1,
        quickSale: true,
        isSelected: true,
      });
    },
  },
};
</script>

<style></style>
