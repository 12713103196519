<template>
  <div>
    <trac-loading v-if="isLoading" />
    <main class="ml-2" v-if="sale.products && filteredCustomers">
      <trac-back-button>Select Store</trac-back-button>

      <trac-modal
        @close="showScannerModalCtrl = false"
        v-if="showScannerModalCtrl"
      >
        <h2 class="">Scan QR Code</h2>
        <StreamBarcodeReader
          class="mt-4"
          v-show="QRReaderActive"
          @decode="onDecode"
          @loaded="onLoaded"
        ></StreamBarcodeReader>
        <div
          class="flex h-32 justify-center items-center"
          v-show="scannerLoading"
        >
          <p class="">Please wait...</p>
        </div>
      </trac-modal>

      <trac-modal @close="quickSaleModalCtrl = false" v-if="quickSaleModalCtrl">
        <QuickSale
          class="mt-4"
          @charge-amount="
            sale.products.push($event);
            quickSaleModalCtrl = false;
          "
        ></QuickSale>
      </trac-modal>

      <trac-modal
        @close="ticketModalCtrl = false"
        v-if="ticketModalCtrl && selectedProducts.length > 0"
      >
        <NewTicket
          :saveTicketName="saveTicketName"
          @create-ticket="saveTicket"
        ></NewTicket>
      </trac-modal>

      <trac-modal
        @close="ticketModalCtrl = false"
        v-if="ticketModalCtrl && selectedProducts.length === 0"
      >
        <OpenTicket :tickets="tickets" @select-ticket="openTicket"></OpenTicket>
      </trac-modal>

      <trac-modal
        class=""
        @close="loyaltyPointsModalCtrl = false"
        v-if="loyaltyPointsModalCtrl"
      >
        <div class="p-8">
          <div class="mt-2">
            <h3 class="font-bold my-4">Customer Points</h3>
            <p class="text-sm my-6 text-gray-700">
              Your customer has
              <span v-if="sale.loyaltyData.active"
                >{{ customerLoyaltyData.Data.Points | formatPrice(false) }} in
                loyalty points</span
              >
              <span
                v-if="sale.loyaltyData.active && sale.storeCreditData.active"
                >&amp;</span
              >
              <span v-if="sale.storeCreditData.active">
                {{ customerStoreCreditData.Data.Credit | formatPrice(false) }}
                in store credit</span
              >. Type in how much you would like to deduct.
            </p>
          </div>
          <div
            class="flex justify-between border p-2 border-gray-300"
            v-if="sale.loyaltyData.active"
          >
            <input
              class="w-full outline-none"
              placeholder="Loyalty (0.00)"
              v-model.number="sale.loyaltyData.redeemValue"
            />
            <span class="text-gray-500">NGN</span>
          </div>
          <div
            class="flex mt-2 justify-between border p-2 border-gray-300"
            v-if="sale.storeCreditData.active"
          >
            <input
              class="w-full outline-none"
              placeholder="Store credit (0.00)"
              v-model.number="sale.storeCreditData.redeemValue"
            />
            <span class="text-gray-500">NGN</span>
          </div>
          <p
            v-if="sale.storeCreditData.active && sale.loyaltyData.active"
            class="text-xs my-6 text-gray-500"
          >
            <span class="text-red-500">*</span> Add amount to both Loyalty
            points and Store credit before you proceed.
          </p>

          <div class="mt-10 flex justify-between">
            <trac-button
              v-if="
                (!sale.storeCreditData.active && sale.loyaltyData.active) ||
                (sale.storeCreditData.active && !sale.loyaltyData.active)
              "
              class=""
              @button-clicked="deductTotal"
            >
              <!-- :disabled="customerLoyaltyData.Data.Points === 0" -->
              <span class="text-xs">USE MAX POINTS</span>
            </trac-button>
            <span v-else></span>
            <!-- If both loyalty and store were selected -->
            <trac-button
              v-if="sale.storeCreditData.active && sale.loyaltyData.active"
              :disabled="activatedPointDeduction"
              class=""
              @button-clicked="deductAmount"
            >
              <span class="text-xs">DEDUCT</span>
            </trac-button>
            <!-- If loyalty was selected -->
            <trac-button
              v-else-if="sale.loyaltyData.active"
              :disabled="
                sale.loyaltyData.redeemValue === '' ||
                sale.loyaltyData.redeemValue > customerLoyaltyData.Data.Points
              "
              class=""
              @button-clicked="deductLoyaltyAmount"
            >
              <span class="text-xs">DEDUCT</span>
            </trac-button>
            <!-- If store credit was selected -->
            <trac-button
              v-else-if="sale.storeCreditData.active"
              :disabled="
                sale.storeCreditData.redeemValue === '' ||
                sale.storeCreditData.redeemValue >
                  customerStoreCreditData.Data.Credit
              "
              class=""
              @button-clicked="deductStoreCreditAmount"
            >
              <span class="text-xs">DEDUCT</span>
            </trac-button>
          </div>
        </div>
      </trac-modal>

      <trac-modal
        v-if="selectedProductForDiscount.product"
        @close="clearSelectedProductForDiscount"
      >
        <div class="">
          <trac-custom-header>
            <template slot="leading" class="uppercase">{{
              selectedProductForDiscount.product.title
            }}</template>
            <template slot="trailing"></template>
          </trac-custom-header>

          <trac-validation-observer slim v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(setupUniqueProductDiscount)">
              <div class="my-4 flex justify-between">
                <p>Change price</p>
                <div class="flex flex-col items-end">
                  <span>{{ sumEachDiscount | formatPrice }}</span>
                  <trac-input-error v-if="sumEachDiscount < 0"
                    >Price can't be below zero</trac-input-error
                  >
                </div>
              </div>
              <trac-radio
                name="discount-type"
                :selectedVal="selectedProductForDiscount.discount_type"
                value="percentage"
                @change="selectedProductForDiscount.discount_type = $event"
                >Percentage</trac-radio
              >
              <trac-radio
                name="discount-type"
                :selectedVal="selectedProductForDiscount.discount_type"
                value="amount"
                @change="selectedProductForDiscount.discount_type = $event"
                >Amount</trac-radio
              >
              <trac-validation-provider
                name="amount"
                :rules="'required|positive' + uniqueDiscountCheck"
                v-slot="{ errors }"
              >
                <!-- @input="selectedProductForDiscount.product.price = sumEachDiscount" -->
                <trac-input
                  v-model.number="selectedProductForDiscount.amount"
                  class="my-4"
                  placeholder="Enter discount..."
                ></trac-input>
                <trac-input-error v-if="errors[0]">{{
                  errors[0]
                }}</trac-input-error>
              </trac-validation-provider>

              <trac-button class="w-full mt-4" buttonType="submit"
                >Add Discount</trac-button
              >
            </form>
          </trac-validation-observer>
        </div>
      </trac-modal>

      <trac-modal
        v-if="totalDiscountModalCtrl"
        @close="totalDiscountModalCtrl = false"
      >
        <div class="">
          <trac-custom-header>
            <template slot="leading" class="uppercase">Total Price</template>
            <template slot="trailing"></template>
          </trac-custom-header>

          <trac-validation-observer slim v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(addTotalDiscount)">
              <div class="my-4 flex justify-between">
                <p>Change price</p>
                <div class="flex flex-col items-end">
                  <span>{{ total | formatPrice }}</span>
                  <trac-input-error v-if="total < 0"
                    >Price can't be below zero</trac-input-error
                  >
                </div>
              </div>
              <trac-radio
                name="discount-type"
                :selectedVal="sale.totalDiscount.discount_type"
                value="percentage"
                @change="sale.totalDiscount.discount_type = $event"
                >Percentage</trac-radio
              >
              <trac-radio
                name="discount-type"
                :selectedVal="sale.totalDiscount.discount_type"
                value="amount"
                @change="sale.totalDiscount.discount_type = $event"
                >Amount</trac-radio
              >
              <trac-validation-provider
                name="amount"
                :rules="'required|positive' + totalDiscountCheck"
                v-slot="{ errors }"
              >
                <trac-input
                  v-model.number="sale.totalDiscount.amount"
                  class="my-4"
                  placeholder="Enter discount..."
                ></trac-input>
                <trac-input-error v-if="errors[0]">{{
                  errors[0]
                }}</trac-input-error>
              </trac-validation-provider>

              <trac-button class="w-full mt-4" buttonType="submit"
                >Add Discount</trac-button
              >
            </form>
          </trac-validation-observer>
        </div>
      </trac-modal>

      <trac-modal
        v-if="variationPrductSelected"
        class="max-w-sm mb-10 w-full"
        @close="variationPrductSelected = null"
      >
        <!-- <span class="font-bold text-sm capitalize">{{
          variationPrductSelected.title
        }}</span> -->

        <trac-custom-header>
          <template slot="leading">{{
            variationPrductSelected.title
          }}</template>
          <template slot="trailing"></template>
        </trac-custom-header>
        <div class="mt-4 overflow-scroll" style="max-height: 300px">
          <button
            @click="selectProduct(variant)"
            class="flex w-full py-4 px-2 justify-between hover:bg-gray-200"
            v-for="(variant, index) in variationPrductSelected.varieties"
            :key="index"
          >
            <div class="flex flex-col items-start">
              <p class="text-xs font-semibold">{{ variant.title }}</p>
              <span class="text-xs text-gray-500"
                >{{ variant.stock }} units(s)</span
              >
            </div>
            <span class="text-xs text-gray-600">{{
              variant.price | formatPrice
            }}</span>
          </button>
        </div>
      </trac-modal>

      <div
        class="
          grid
          pr-4
          md:pr-0 md:flex md:flex-row
          mt-14
          mb-8
          md:items-end md:justify-between
        "
      >
        <div class="flex-col mb-2 md:mb-0">
          <h3 class="font-bold mt-4 text-lg">POS</h3>
        </div>

        <div class="flex-col">
          <div class="grid md:flex flex-row gap-5">
            <trac-button
              class="uppercase w-full whitespace-no-wrap"
              @button-clicked="openScanner"
              >Scan Product</trac-button
            >
            <trac-button
              @button-clicked="openTicketModal"
              class="uppercase w-full whitespace-no-wrap"
              >{{
                selectedProducts.length === 0 ? "Open Ticket" : "Save Ticket"
              }}</trac-button
            >
            <trac-button
              class="uppercase w-full whitespace-no-wrap"
              @button-clicked="quickSaleModalCtrl = true"
              >Quick Sale</trac-button
            >
          </div>
        </div>
      </div>
      <!-- <div class="flex w-full items-center">
        <div class="relative max-w-sm rounded-md w-full flex items-center">
          <input
            type="text"
            placeholder="Search by account name or amount paid"
            class="input-shadow py-2 w-full pl-5 text-xs focus:outline-none leading-7 tracking-wide text-primaryGray"
            v-model="search"
          />
          <svg
            class="w-4 h-4 absolute right-0 mr-6"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="6.56705"
              cy="6.61686"
              r="5.39909"
              stroke="#253B95"
              stroke-width="1.06786"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M13.3156 13.3659L10.3799 10.4302"
              stroke="#253B95"
              stroke-width="1.06786"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="flex items-center ml-5">
          <div class="text-xs">Category:</div>
          <div class="ml-4">
            <trac-dropdown
              title="Select Category"
              :menuItems="categories"
              v-model="category"
            >
            </trac-dropdown>
          </div>
        </div>
      </div> -->
      <!-- <div class="text-xs">Category:</div>
          <div class="ml-4">
            <trac-dropdown
              title="Select Category"
              :menuItems="categories"
              v-model="category"
            >
            </trac-dropdown>
          </div> -->
      <div class="dashboard-hold grid mt-12 mb-8 justify-between pr-4 md:pr-0">
        <div class="flex-column w-12/12 h-screen">
          <div
            class="bg-white rounded-lg overflow-auto big-shadow h-full"
            @scroll="checkScroll($event)"
          >
            <div class="flex pt-8 w-full justify-center mb-6">
              <div class="px-6 w-full">
                <button
                  :class="
                    selectedCategory.trim().toLowerCase() ===
                    cat.name.trim().toLowerCase()
                      ? 'bg-primaryBlue text-white'
                      : ''
                  "
                  @click="filterByCategory(cat)"
                  class="
                    inline-block
                    outline-none
                    px-4
                    border border-primaryBlue
                    capitalize
                    m-1
                  "
                  v-for="(cat, index) in categories"
                  :key="index"
                >
                  <span class="text-xs py-2 px-4">{{ cat.name }}</span>
                </button>
              </div>
            </div>
            <div class="mt-2 mb-5 mx-8 flex flex-row items-center">
              <div
                class="
                  flex flex-row
                  bg-white
                  items-center
                  rounded
                  big-shadow
                  justify-between
                  px-2
                  w-full
                "
              >
                <span class="text-xs font-light text-primaryGray w-full">
                  <input
                    class="w-full py-2 px-4 outline-none"
                    type="text"
                    name=""
                    id=""
                    :placeholder="`Search from ${selectedCategory}...`"
                    v-model.trim="search"
                  />
                  <!-- @input="filterBySearch" -->
                </span>
                <img src="../../assets/svg/Search_icon.svg" />
              </div>
            </div>
            <div>
              <div v-if="sortedProducts.length > 0">
                <div
                  class="
                    grid grid-cols-2
                    lg:grid-cols-4
                    gap-4
                    mr-8
                    ml-8
                    mb-8
                    mt-8
                  "
                >
                  <div
                    @click="selectProduct(product)"
                    v-for="(product, i) in sortedProducts"
                    :key="i"
                    class="
                      shadow-md
                      w-full
                      rounded
                      flex
                      mx-auto
                      my-auto
                      shadow-toggle
                      bg-white
                      cursor-pointer
                    "
                  >
                    <div class="flex flex-col w-full items-end">
                      <div class="flex-col my-auto h-full mx-auto w-10/12">
                        <trac-image-placeholder
                          :size="'h-24'"
                          :name="product.title"
                          :imgURL="product.image"
                        ></trac-image-placeholder>
                      </div>
                      <div
                        class="
                          flex
                          w-4/5
                          pt-3
                          mx-auto
                          mb-3
                          border-t
                          text-xs
                          font-medium
                          justify-center
                          items-center
                          capitalize
                        "
                      >
                        <p class="text-center">{{ product.title }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="flex justify-center items-center w-full">
                  <pagination
                    class="mt-4 mx-auto"
                    v-if="sortedProducts.length > 100"
                    v-model="page"
                    :records="sortedProducts.length"
                    :per-page="10"
                  />
                </div> -->
              </div>

              <div class="text-xs mt-8 ml-6 h-56" v-else>
                <trac-center-data>
                  <p
                    class="text-gray-500"
                    v-if="search.length === 0 || sortedProducts.length === 0"
                  >
                    There is no available product.
                  </p>
                  <p class="text-gray-500" v-else>No match found.</p>
                </trac-center-data>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-column h-auto md:ml-2 mt-4 md:mt-0">
          <div class="bg-white pt-8 pb-5 px-8 rounded-lg big-shadow">
            <!-- <trac-input placeholder="Add Customer here"></trac-input> -->
            <trac-dropdown-exteneded
              class="absolute"
              :neededProperty="'customerFullName'"
              :selector="salectedCustomer || 'Select Customer'"
              :options="sortCustomersNames"
              :shouldFilter="true"
              :addProp="true"
              @optionSelected="selectCustomer($event)"
              @filter-list="filterCustomersList"
              @add-prop="addCustomerOnTheFly"
            ></trac-dropdown-exteneded>

            <div class="bg-white border mt-5 rounded-lg overflow-auto">
              <table class="w-full table-auto overflow-auto">
                <tbody class="overflow-auto">
                  <tr
                    v-for="(product, index) in selectedProducts"
                    class="border-b cursor-pointer"
                    :key="index"
                  >
                    <div class="flex flex-wrap">
                      <div
                        class="
                          flex flex-row
                          my-auto
                          w-full
                          items-center
                          justify-between
                        "
                      >
                        <div class="text-xs py-4 capitalize pl-4 w-4/12">
                          <ul>
                            <li class="text-xs font-medium">
                              {{ product.title || "Quick Sale" }}
                            </li>
                            <li class="text-xs text-primaryBlue">
                              <span
                                v-if="product.quickSale"
                                class="cursor-pointer"
                                >{{ product.count }} item(s)</span
                              >
                              <span v-else class="cursor-pointer">{{
                                product.price | formatPrice
                              }}</span>
                            </li>
                          </ul>
                        </div>
                        <div class="text-xs text-center w-4/12 py-4">
                          <div
                            v-if="!product.quickSale"
                            class="
                              flex flex-row
                              gap-3
                              justify-center
                              items-center
                            "
                          >
                            <img
                              class="cursor-pointer"
                              @click="handleQty('increase', product)"
                              src="../../assets/svg/add.svg"
                            />
                            <span>
                              {{ product.quantity || product.itmeCount }}</span
                            >
                            <img
                              class="cursor-pointer"
                              @click="handleQty('decrease', product)"
                              src="../../assets/svg/minus.svg"
                            />
                          </div>
                        </div>
                        <div
                          class="
                            text-xs
                            font-medium
                            text-right
                            pr-4
                            w-4/12
                            py-4
                          "
                        >
                          <span v-if="product.quickSale">{{
                            product.quickSaleTotal | formatPrice
                          }}</span>
                          <span v-else>{{
                            `${product.price * product.quantity}` | formatPrice
                          }}</span>
                        </div>
                        <div
                          v-if="!product.quickSale"
                          @click="showDiscountMenu(product)"
                          class="w-1/12 cursor-pointer"
                        >
                          <img src="../../assets/svg/down_arrow.svg" />
                        </div>
                        <div class="mr-2">
                          <img
                            class="cursor-pointer"
                            src="../../assets/svg/close_btn.svg"
                            alt=""
                            @click="removeSelectedProduct(product)"
                          />
                        </div>
                      </div>
                      <div
                        v-if="product.active"
                        class="
                          flex flex-row
                          w-full
                          px-4
                          py-2
                          justify-between
                          items-center
                          bg-veryLight
                        "
                      >
                        <div class="w-4/12">
                          <ul>
                            <li class="text-xs">Unit Price</li>
                            <li class="text-xs text-primaryBlue">
                              <trac-input
                                v-model.number="product.price"
                                class="outline-none py-1"
                              ></trac-input>
                            </li>
                          </ul>
                        </div>
                        <div class="text-xs">
                          <ul>
                            <li class="text-xs">Discount:</li>
                            <li class="text-xs text-primaryBlue">
                              <button
                                @click="
                                  product.price = product.initial_price;
                                  selectedProductForDiscount.product = product;
                                "
                                class="
                                  uppercase
                                  text-xs
                                  bg-primaryBlue
                                  text-white
                                  p-3
                                "
                              >
                                Add Discount
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </tr>
                </tbody>
              </table>
              <div class="p-6">
                <div class="flex flex-row items-center h-full">
                  <trac-button
                    @button-clicked="clearCart"
                    class="mx-auto text-red-600 font-semibold"
                    variant="xsall"
                    >Clear Sale</trac-button
                  >
                </div>
              </div>
            </div>
            <div class="mt-6">
              <span class="text-xs font-bold">Add Discount:</span>
              <div class="flex flex-wrap gap-5 mt-4">
                <div
                  v-for="(discount, i) in discounts"
                  :key="i"
                  @click="setTotalDiscount(discount)"
                  class="flex-column py-1 px-1 rounded cursor-pointer"
                  :class="
                    sale.totalDiscount.discount_type === 'percentage' &&
                    sale.totalDiscount.percentage === discount
                      ? 'bg-primaryBlue text-white'
                      : 'bg-primaryGray text-black'
                  "
                >
                  <span class="text-xs font-bold">{{ `${discount} %` }}</span>
                </div>
                <div
                  class="flex-column py-1 px-3 rounded cursor-pointer"
                  :class="
                    sale.totalDiscount.discount_type !== 'percentage' &&
                    sale.totalDiscount.amount > 0
                      ? 'bg-primaryBlue text-white'
                      : 'bg-primaryGray text-black'
                  "
                  @click="openTotalDiscountModal"
                >
                  <span class="text-xs font-bold">+</span>
                </div>
                <div class="flex justify-between items-center w-full">
                  <span
                    v-if="totalPlaceholder - total > 0"
                    class="
                      text-xs
                      font-bold
                      text-white
                      bg-red-600
                      p-1
                      rounded-sm
                    "
                  >
                    - {{ (totalPlaceholder - total) | formatPrice }}</span
                  >
                  <span class="p-1"></span>
                  <span
                    @click="setTotalDiscount(0)"
                    class="text-xs font-normal text-primaryBlue cursor-pointer"
                  >
                    Clear Discount</span
                  >
                </div>
                <div class="w-full" v-if="businesCustomerLoaylty">
                  <hr class="my-4" />
                  <p class="font-bold">Select:</p>
                  <div class="mt-4">
                    <!-- <p class="text-sm mr-4"></p> -->
                    <div class="grid md:flex justify-between">
                      <trac-checkbox
                        :disableCheckbox="customerIsSelected"
                        @check="checkLoyalty"
                        :isChecked="sale.loyaltyData.active"
                        class="mb-2"
                      >
                        <span
                          class="text-primaryBlue text-sm pl-2"
                          :class="
                            customerIsSelected ? 'cursor-not-allowed' : ''
                          "
                          >Redeem Loyalty</span
                        >
                      </trac-checkbox>
                      <trac-checkbox
                        :disableCheckbox="customerIsSelected"
                        @check="checkStoreCredit"
                        :isChecked="sale.storeCreditData.active"
                        class="mt-2"
                      >
                        <span
                          class="text-primaryBlue text-sm pl-2"
                          :class="
                            customerIsSelected ? 'cursor-not-allowed' : ''
                          "
                          >Use store credit</span
                        >
                      </trac-checkbox>
                    </div>
                  </div>
                  <p class="text-xs text-gray-600 mt-4">
                    <span class="text-red-600">*</span> Select customer before
                    adding loyalty points to sale.
                  </p>
                </div>
                <div class="flex-column w-full mt-2">
                  <trac-button
                    :disabled="selectedProducts.length === 0"
                    class="uppercase w-full"
                    @button-clicked="gotoSalesPayment"
                  >
                    Charge {{ total | formatPrice }}</trac-button
                  >
                </div>

                <trac-textarea
                  v-model="sale.sale_description"
                  placeholder="Note"
                  class="w-full"
                ></trac-textarea>

                <p v-if="businesCustomerLoaylty" class="text-xs mt-4">
                  To use this feature enable loyalty in the
                  <button
                    class="outline-none text-xs text-primaryBlue"
                    @click="$router.push({ name: 'CustomerLoyalty' })"
                  >
                    settings.
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Pagination from "vue-pagination-2";
import {
  DELETE_LOCAL_DB_DATA,
  GET_LOCAL_DB_DATA,
  GET_USER_BUSINESS_DATA,
  GET_USER_BUSINESS_ID,
  GET_USER_DATA,
  SAVE_LOCAL_DB_DATA,
} from "../../browser-db-config/localStorage";
import { StreamBarcodeReader } from "vue-barcode-reader";
import NewTicket from "./NewTicket";
import OpenTicket from "./OpenTicket";
import QuickSale from "./quickSale";
import axios from "axios";
import Fuse from "fuse.js";
import {
  CONNECT_TO_DB,
  GET_DB_DATA,
  GET_OBJECT_STORE,
} from "../../browser-db-config/indexedDB";
import { eventBus } from "./../../main";

export default {
  name: "PosDashboard",
  // ================================================================================================= Components
  components: {
    NewTicket,
    OpenTicket,
    QuickSale,
    Pagination,
    StreamBarcodeReader,
  },
  // ================================================================================================= Data
  data() {
    return {
      category: "All",
      search: "",
      // categories: [],
      isLoading: false,
      scannerLoading: false,
      quickSaleModalCtrl: false,
      showScannerModalCtrl: false,
      QRReaderActive: false,
      ticketModalCtrl: false,
      businesCustomerLoaylty: false,
      allowSetLoyalty: false,
      allowStoreCredit: false,
      totalDiscountModalCtrl: false,
      loyaltyPointsModalCtrl: false,
      variationPrductSelected: null,
      customerLoyaltyData: {},
      customerStoreCreditData: {},
      selectedCategory: "All",
      categories: null,
      showingProducts: [],
      selectedProductForDiscount: {
        product: null,
        discount_type: "percentage",
        amount: "",
      },
      createCustomerOnTheFly: null,
      sale: {
        sale_description: "",
        totalDiscount: {
          discount_type: "percentage",
          amount: 0,
          percentage: 0,
        },
        products: null,
        selectedDiscount: 0,
        customer: {
          customerFullName: null,
        },
        loyaltyData: {
          redeemValue: 0,
          active: false,
        },
        storeCreditData: {
          redeemValue: 0,
          active: false,
        },
      },
      customers: [],
      // sale.loyaltyData.redeemValue: "",
      saveTicketName: "",
      searchedProduct: "",
      filteredCustomers: null,
      store: null,
      discounts: [2, 5, 10, 15],
      active: false,
      page: 1,
    };
  },
  // ================================================================================================= Created
  async created() {
    this.isLoading = true;
    let res = await this.$store.dispatch(
      "FETCH_ALL_CATEGORIES_WITH_SUB_CATEGORIES"
    );

    let mappedRes = res.data.map((x) => {
      x.category.name == "" ? (x.name = "All") : x.category.name;
      let k = {};
      k.name = x.category.name;
      return k;
    });

    this.categories = mappedRes;

    this.businesCustomerLoaylty = GET_USER_BUSINESS_DATA().loyalty_active;
    this.store = GET_LOCAL_DB_DATA("pos-sales-store");
    if (!this.store) {
      this.$router.replace({ name: "Pos" });
    }
    await this.fetchAllCategories();
    await this.fetchAllCustomers();
    await this.fetchAllProducts();

    // Setting products to show an initial amount
    // this.sortedProducts = this.sale.products.slice(0, 20);
    this.showingProducts = this.sale.products.slice(0, 30);

    const tracSale = GET_LOCAL_DB_DATA("sale-details");

    if (tracSale) {
      this.sale = tracSale;
    }

    this.updateDOMData();

    this.isLoading = false;
  },
  mounted() {
    this.updateDOMData();
  },
  // ================================================================================================= beforeEnter
  beforeRouteEnter(to, from, next) {
    if (from.name) {
      if (from.name !== "PosPayment" && from.name !== "AddCustomer") {
        DELETE_LOCAL_DB_DATA("sale-details");
      }
    }

    if (!GET_LOCAL_DB_DATA("pos-sales-store")) {
      next({ name: "Pos" });
    } else {
      next();
    }
  },
  // ================================================================================================= Destroyed
  destroyed() {
    if (
      this.$route.name !== "PosPayment" &&
      this.$route.name !== "AddCustomer"
    ) {
      DELETE_LOCAL_DB_DATA("sale-details");
    }
  },
  // ================================================================================================= Computed
  computed: {
    activatedPointDeduction() {
      return (
        this.sale.loyaltyData.redeemValue === 0 ||
        this.sale.loyaltyData.redeemValue === "" ||
        this.sale.loyaltyData.redeemValue >
          this.customerLoyaltyData.Data.Points ||
        this.sale.storeCreditData.redeemValue >
          this.customerStoreCreditData.Data.Credit ||
        this.sale.storeCreditData.redeemValue === 0 ||
        this.sale.storeCreditData.redeemValue === "" ||
        this.sale.storeCreditData.redeemValue +
          this.sale.loyaltyData.redeemValue >
          this.total
      );
    },
    sortedShowingProducts() {
      let products = this.sale.products
        .filter((prod) => !prod.quickSale)
        .sort((a, b) => (a.title > b.title ? 1 : -1));

      if (this.category.toLowerCase() === "all") {
        return this.showingProducts;
      } else {
        return products.filter(
          (product) =>
            product.title.toLowerCase().includes(this.search.toLowerCase()) &&
            product.category.toLowerCase() === this.category.toLowerCase()
        );
      }
    },
    categoryFilteredProducts() {
      if (this.selectedCategory === "All") {
        return this.sortedProducts.filter((prod) =>
          prod.title
            .trim()
            .toLowerCase()
            .includes(this.searchedProduct.trim().toLowerCase())
        );
      } else {
        return this.sortedProducts
          .filter((prod) => {
            return (
              prod.category.trim().toLowerCase() ===
              this.selectedCategory.trim().toLowerCase()
            );
          })
          .filter((prod) =>
            prod.title
              .trim()
              .toLowerCase()
              .includes(this.searchedProduct.trim().toLowerCase())
          );
      }
    },
    customerIsSelected() {
      return this.sale.customer._id === undefined;
    },
    salectedCustomer() {
      // this.sale = { ...this.sale, customer: this.sale.customer };
      return this.sale.customer.customerFullName;
    },
    sumEachDiscount() {
      if (this.selectedProductForDiscount.discount_type === "percentage") {
        return (
          this.selectedProductForDiscount.product.price *
          (1 - this.selectedProductForDiscount.amount * 0.01)
        );
      } else {
        return (
          this.selectedProductForDiscount.product.price -
          this.selectedProductForDiscount.amount
        );
      }
    },
    uniqueDiscountCheck() {
      if (this.selectedProductForDiscount.discount_type === "percentage") {
        return "|minmax:0,100";
      } else {
        return "";
      }
    },
    totalDiscountCheck() {
      if (this.sale.totalDiscount.discount_type === "percentage") {
        return "|minmax:0,100";
      } else {
        return "";
      }
    },
    selectedProducts() {
      const selections = [];

      this.sale.products.forEach((product) => {
        if (product.isSelected) {
          selections.push(product);
        } else if (product.variation) {
          product.varieties.forEach((va) => {
            if (va.isSelected) {
              selections.push(va);
            }
          });
        }
      });

      return selections;
    },
    totalPlaceholder() {
      return this.selectedProducts.reduce((total, product) => {
        if (product.quickSale) {
          return total + product.quickSaleTotal;
        } else {
          return total + product.quantity * product.price;
        }
      }, 0);
    },
    total() {
      return (
        this.selectedProducts.reduce((total, product) => {
          if (product.quickSale) {
            return total + product.quickSaleTotal;
          } else {
            return total + product.quantity * product.price;
          }
        }, 0) -
        (this.sale.totalDiscount.discount_type === "percentage"
          ? this.totalPlaceholder * this.sale.totalDiscount.amount * 0.01
          : this.sale.totalDiscount.amount)
      );
    },
    sortedProducts() {
      return this.showingProducts
        .filter((prod) => {
          if (this.category.toLowerCase() === "all") {
            return this.search.length > 0
              ? prod.title.toLowerCase().includes(this.search.toLowerCase())
              : prod;
          } else {
            return (
              prod.category.toLowerCase() === this.category.toLowerCase() &&
              prod.title.toLowerCase().includes(this.search.toLowerCase())
            );
          }
        })
        .sort((a, b) =>
          a.title.trim().toLowerCase() > b.title.trim().toLowerCase() ? 1 : -1
        );
    },
    sortCustomersNames() {
      return this.filteredCustomers.sort((a, b) =>
        a.customerFullName.toLowerCase() > b.customerFullName.toLowerCase()
          ? 1
          : -1
      );
    },
  },
  // ================================================================================================= Methods
  methods: {
    onLoaded(result) {
      this.scannerLoading = false;
      this.QRReaderActive = true;
    },
    onDecode(result) {
      const scannedProduct = (this.sale.products || []).find(
        (product) => product.barcode === result
      );
      if (scannedProduct) {
        this.selectProduct(scannedProduct);
        this.showScannerModalCtrl = false;
      } else {
        eventBus.$emit("trac-alert", {
          message: "Product doesn't exist.",
        });
      }
    },
    checkScroll(e) {
      const myDiv = e.target;
      const shownProductsSofar = this.showingProducts;

      if (myDiv.offsetHeight + myDiv.scrollTop >= myDiv.scrollHeight) {
        if (shownProductsSofar.length === this.sale.products.length) {
        } else {
          this.showingProducts.push(
            ...this.sale.products.slice(
              shownProductsSofar.length,
              shownProductsSofar.length + 10
            )
          );
        }
      }
    },
    filterByCategory(cat) {
      this.selectedCategory = cat.name;
      this.category = cat.name;
    },
    openScanner() {
      this.scannerLoading = true;
      this.showScannerModalCtrl = true;
      this.QRReaderActive = true;
    },
    panCategoriesLeft(e) {
      if (e.target.nodeName === "BUTTON") {
        e.target.nextElementSibling.scrollBy({
          left: -250,
          behavior: "smooth",
        });
      } else {
        e.target.parentNode.nextElementSibling.scrollBy({
          left: -250,
          behavior: "smooth",
        });
      }
    },
    panCategoriesRight(e) {
      if (e.target.nodeName === "BUTTON") {
        e.target.previousElementSibling.scrollBy({
          left: 250,
          behavior: "smooth",
        });
      } else {
        e.target.parentNode.previousElementSibling.scrollBy({
          left: 250,
          behavior: "smooth",
        });
      }
    },
    updateDOMData() {
      this.createCustomerOnTheFly = GET_LOCAL_DB_DATA(
        "create-customer-on-the-fly"
      );

      if (this.createCustomerOnTheFly) {
        DELETE_LOCAL_DB_DATA("create-customer-on-the-fly");
        this.sale = this.createCustomerOnTheFly.sale;
        this.sale.customer.customerFullName =
          this.sale.customer.customerFullName;
      }

      const tracSale = GET_LOCAL_DB_DATA("sale-details");

      if (tracSale) {
        this.sale = tracSale;
      }
    },
    addCustomerOnTheFly() {
      const payload = {
        origin: {
          name: "POS",
        },
        sale: this.sale,
      };

      SAVE_LOCAL_DB_DATA("create-customer-on-the-fly", payload);
      this.$router.push({ name: "AddCustomer" });
    },
    checkLoyalty(e) {
      this.sale.loyaltyData.active = e.checked;
      if (e.checked) {
      } else {
        this.sale.loyaltyData.redeemValue = 0;
      }
    },
    checkStoreCredit(e) {
      this.sale.storeCreditData.active = e.checked;
      if (e.checked) {
      } else {
        this.sale.storeCreditData.redeemValue = 0;
      }
    },
    openTotalDiscountModal() {
      if (this.selectedProducts.length > 0) {
        this.totalDiscountModalCtrl = true;
      }
    },
    addTotalDiscount() {
      this.sale.totalDiscount.percentage = this.sale.totalDiscount.amount;
      this.totalDiscountModalCtrl = false;
    },
    async openTicketModal() {
      if (this.selectedProducts.length === 0) {
        this.isLoading = true;
        await this.fetchTickets();
        this.isLoading = false;
      } else {
        this.ticketName = this.ticketName;
      }
      this.ticketModalCtrl = true;
    },
    selectProductForDiscount(product) {
      this.selectedProductForDiscount = product;
    },
    setupUniqueProductDiscount() {
      this.sale.products = this.sale.products.map((product) => {
        if (product.variation) {
          product.varieties = product.varieties.map((va) => {
            if (va._id === this.selectedProductForDiscount.product._id) {
              return {
                ...va,
                initial_price: va.price,
                price: this.sumEachDiscount,
                discount_type: this.selectedProductForDiscount.discount_type,
                discount_amount: this.selectedProductForDiscount.amount,
              };
            } else {
              return va;
            }
          });

          return product;
        } else if (
          product._id === this.selectedProductForDiscount.product._id
        ) {
          return {
            ...product,
            initial_price: product.price,
            price: this.sumEachDiscount,
            discount_type: this.selectedProductForDiscount.discount_type,
            discount_amount: this.selectedProductForDiscount.amount,
          };
        } else {
          return product;
        }
      });
      this.clearSelectedProductForDiscount();
    },
    clearSelectedProductForDiscount() {
      this.selectedProductForDiscount = {
        product: null,
        discount_type: "percentage",
        amount: "",
      };
    },
    setTotalDiscount(discount) {
      // if (this.selectedProducts.length > 0) {
      this.sale.totalDiscount = {
        discount_type: "percentage",
        amount: discount,
        percentage: discount,
      };
      // }
    },
    getSpecificStore(prod) {
      return (
        (prod.store_stock || []).find((ss) => ss.store_id === this.store._id) ||
        {}
      );
    },
    handleQty(type, product) {
      this.selectedProducts.forEach((prod) => {
        if (type === "increase" && prod._id === product._id) {
          const selStore = this.getSpecificStore(prod);

          if (!prod.manage_stock) {
            prod.quantity += 1;
          } else if (selStore.stock > prod.quantity) {
            prod.quantity += 1;
          } else {
            eventBus.$emit("trac-alert", {
              message: `You can\'t exceed the stock level of ${prod.title} for ${selStore.store_name} store.`,
            });
          }
        } else if (type === "decrease" && prod._id === product._id) {
          if (prod.quantity > 1) {
            prod.quantity -= 1;
          }
        }
      });
    },
    filterCustomersList(input) {
      if (input.length > 0 && input.length < 5) {
        return;
      }

      let searchedData = [];
      const options = {
        keys: ["customerFullName", "phone"],
      };
      const fuse = new Fuse(this.customers, options);
      if (input == "") {
        this.filteredCustomers = this.customers.slice(0, 30);
      } else {
        this.filteredCustomers = this.customers.filter((customer) => {
          customer;
          return customer.phone
            ? customer.customerFullName
                .toLowerCase()
                .includes(input.toLowerCase()) || customer.phone.includes(input)
            : customer.customerFullName
                .toLowerCase()
                .includes(input.toLowerCase());
        });
      }
    },
    selectCustomer(option) {
      this.sale.customer = option;
    },
    removeSelectedProduct(product) {
      this.sale.products = this.sale.products.map((prod, i) => {
        if (prod.variation) {
          prod.varieties = prod.varieties.map((va) => {
            if (va._id === product._id) {
              return {
                ...va,
                isSelected: false,
                quantity: 1,
                price: va.initial_price,
                discount_type: "",
                discount_amount: 0,
              };
            } else {
              return va;
            }
          });
        }
        if (prod._id === product._id) {
          return {
            ...prod,
            isSelected: false,
            quantity: 1,
            price: prod.initial_price,
            discount_type: "",
            discount_amount: 0,
          };
        } else {
          return prod;
        }
      });
    },
    queryVariations(prod, product) {
      return {
        ...prod,
        varieties: prod.varieties.map((va) => {
          if (va._id === product._id) {
            return { ...va, active: !va.active };
          } else {
            return { ...va, active: false };
          }
        }),
      };
    },
    clearCart() {
      this.sale.products = this.sale.products.map((prod, i) => {
        if (prod.variation) {
          prod.varieties = prod.varieties.map((va) => {
            return {
              ...va,
              isSelected: false,
              quantity: 1,
              price: va.initial_price,
            };
          });
          return prod;
        } else {
          return {
            ...prod,
            isSelected: false,
            quantity: 1,
            price: prod.initial_price,
          };
        }
      });

      this.sale.totalDiscount = {
        discount_type: "percentage",
        amount: 0,
        percentage: 0,
      };

      DELETE_LOCAL_DB_DATA("sale-details");
    },
    showDiscountMenu(product) {
      this.sale.products = this.sale.products.map((prod) => {
        if (prod.variation) {
          return this.queryVariations(prod, product);
        }

        if (prod._id === product._id) {
          return { ...prod, active: !product.active };
        } else {
          return { ...prod, active: false };
        }
      });
    },
    deductTotal() {
      if (this.sale.loyaltyData.active) {
        this.sale.loyaltyData.redeemValue =
          this.customerLoyaltyData.Data.Points > this.total
            ? this.total
            : this.customerLoyaltyData.Data.Points;
        this.deductLoyaltyAmount();
      }

      if (this.sale.storeCreditData.active) {
        this.sale.loyaltyData.redeemValue =
          this.customerStoreCreditData.Data.Credit > this.total
            ? this.total
            : this.customerStoreCreditData.Data.Credit;
        this.deductStoreCreditAmount();
      }
    },
    deductAmount() {
      if (
        this.sale.loyaltyData.redeemValue > this.customerLoyaltyData.Data.Points
      ) {
        eventBus.$emit("trac-alert", {
          message:
            "Customer loyalty points can't be lesser than amount entered.",
        });
        return;
      }

      if (
        this.sale.storeCreditData.redeemValue >
        this.customerStoreCreditData.Data.Credit
      ) {
        eventBus.$emit("trac-alert", {
          message:
            "Customer customer credit points can't be lesser than amount entered.",
        });
        return;
      }

      const saleForPayment = { ...this.sale, store: this.store };
      SAVE_LOCAL_DB_DATA("sale-details", saleForPayment);
      this.$router.push({ name: "PosPayment" });
    },
    deductLoyaltyAmount() {
      if (
        this.sale.loyaltyData.redeemValue > this.customerLoyaltyData.Data.Points
      ) {
        eventBus.$emit("trac-alert", {
          message:
            "Customer loyalty points can't be lesser than amount entered.",
        });
        return;
      }

      const saleForPayment = { ...this.sale, store: this.store };
      SAVE_LOCAL_DB_DATA("sale-details", saleForPayment);
      this.$router.push({ name: "PosPayment" });
    },
    deductStoreCreditAmount() {
      if (
        this.sale.storeCreditData.redeemValue >
        this.customerStoreCreditData.Data.Credit
      ) {
        eventBus.$emit("trac-alert", {
          message:
            "Customer customer credit points can't be lesser than amount entered.",
        });
        return;
      }

      const saleForPayment = { ...this.sale, store: this.store };
      SAVE_LOCAL_DB_DATA("sale-details", saleForPayment);
      this.$router.push({ name: "PosPayment" });
    },
    async fetchAllData() {},
    async gotoSalesPayment() {
      if (this.sale.loyaltyData.active) {
        // const offlineLoyaltyPointsDB = await CONNECT_TO_DB(
        //   "offline_db_all_loyalty_points",
        //   1,
        //   "all_loyalty_points"
        // );
        let offlineAllLoyaltyPoints = await GET_OBJECT_STORE(
          "offline_db_all_loyalty_points",
          "all_loyalty_points"
        );

        if (offlineAllLoyaltyPoints) {
          this.customerLoyaltyData.Data = (
            offlineAllLoyaltyPoints.Data || []
          ).find(
            (loyaltyPointData) =>
              loyaltyPointData.CustomerID === this.sale.customer._id
          ) || { Points: 0 };
        }
      }

      if (this.sale.storeCreditData.active) {
        // const offlineStoreCreditDB = await CONNECT_TO_DB(
        //   "offline_db_all_store_credits",
        //   1,
        //   "all_store_credits"
        // );
        let offlineStoreCredit = await GET_OBJECT_STORE(
          "offline_db_all_store_credits",
          "all_store_credits"
        );

        if (offlineStoreCredit) {
          this.customerStoreCreditData.Data = (
            offlineStoreCredit.Data || []
          ).find(
            (storeCreditData) =>
              storeCreditData.CustomerID === this.sale.customer._id
          ) || { Credit: 0 };
        }
      }

      if (this.sale.storeCreditData.active || this.sale.loyaltyData.active) {
        this.loyaltyPointsModalCtrl = true;
      }

      if (!this.sale.loyaltyData.active && !this.sale.storeCreditData.active) {
        const saleForPayment = { ...this.sale, store: this.store };
        SAVE_LOCAL_DB_DATA("sale-details", saleForPayment);
        this.$router.push({ name: "PosPayment" });
      }
    },
    activateDiscount(index) {
      this.discounts[index].active = !this.discounts[index].active;
      for (let i = 0; i < this.discounts.length; i++) {
        if (i !== index) {
          this.discounts[i].active = false;
        }
      }
    },
    async fetchAllCategories() {
      await this.$store.dispatch("FETCH_ALL_CATEGORIES_WITH_SUB_CATEGORIES");
      const res = this.$store.getters["GET_ALL_CATEGORIES_WITH_SUB"];

      if (res) {
        // this.categories = res;
        const allCat = (res.data || []).find(
          (c) => c.category.name.toLowerCase() === "all"
        );
        const otherCats = res.data
          .filter((c) => c.category.name.toLowerCase() !== "all")
          .sort((a, b) => (a.category.name > b.category.name ? 1 : -1));

        if (allCat) {
          this.categories = [
            {
              name: allCat.category.name,
              sub: allCat.Subs,
              details: allCat.name,
            },
            ...otherCats.map((cat) => {
              return {
                name: cat.category.name,
                sub: cat.Subs,
                details: cat.name,
              };
            }),
          ];
        } else {
          this.categories = [
            { name: "All" },
            ...otherCats.map((cat) => {
              return {
                name: cat.category.name,
                sub: cat.Subs,
                details: cat.name,
              };
            }),
          ];
        }
      }
    },
    selectProduct(product) {
      if (product.variation) {
        this.variationPrductSelected = product;
        return;
      }

      const selStore = this.getSpecificStore(product);

      if (product.manage_stock) {
        if (selStore.stock === undefined || selStore.stock === null) {
          eventBus.$emit("trac-alert", {
            message: `${product.title} is not available in ${this.store.name} store.`,
          });
          return;
        }

        if (selStore.stock <= product.quantity) {
          eventBus.$emit("trac-alert", {
            message: `You can\'t exceed the stock level of ${product.title} for ${selStore.store_name} store.`,
          });
          return;
        }
      }

      this.sale.products = this.sale.products.map((prod, i) => {
        if (prod.variation) {
          prod.varieties = prod.varieties.map((va) => {
            if (va._id === product._id) {
              return {
                ...va,
                isSelected: true,
                quantity: va.isSelected ? (va.quantity += 1) : 1,
              };
            } else {
              return va;
            }
          });
        }
        if (prod._id === product._id) {
          return {
            ...prod,
            isSelected: true,
            quantity: prod.isSelected ? (prod.quantity += 1) : 1,
          };
        } else {
          return prod;
        }
      });

      // This updated the computed property "sortedProducts"
      this.showingProducts = this.sale.products.slice(
        0,
        this.showingProducts.length
      );

      this.variationPrductSelected = null;
    },
    openTicket(ticket) {
      this.saveTicketName = ticket.ticket_name;

      this.sale._id = ticket._id;
      this.sale.totalDiscount.amount = ticket.discount;
      this.sale.totalDiscount.discount_type = "amount";

      this.sale.products = this.sale.products.map((product, i) => {
        if (product.variation) {
          product.varieties = product.varieties.map((v) => {
            ticket.items.forEach((item) => {
              if (v._id === item._id) {
                v.isSelected = true;
                v.quantity = item.quantity;
                v.is_custom = item.is_custom;
                v.discount = item.discount;
              }
            });
            return v;
          });
        } else {
          ticket.items.forEach((item) => {
            if (product._id === item._id) {
              product.isSelected = true;
              product.quantity = item.quantity;
              product.is_custom = item.is_custom;
              product.discount = item.discount;
            }
          });
        }

        return product;
      });

      // check for quick sale data
      ticket.items.forEach((item) => {
        if (item.name === "Quick Sale Items") {
          this.sale.products.push({
            ...item,
            isSelected: true,
            title: item.name,
            quickSale: true,
            quickSaleTotal: item.price * item.quantity,
          });
        }
      });

      if (
        !this.sale.products.some(
          (prod) => prod.isSelected || prod.name === "Quick Sale Items"
        ) &&
        !this.sale.products.some((prod) => {
          if (prod.variation) {
            return prod.varieties.some((v) => {
              return v.isSelected;
            });
          }
        })
      ) {
        eventBus.$emit("trac-alert", {
          message: `The product(s) on ticket:${ticket.ticket_name} are no longer available.`,
        });
        return;
      }
      this.ticketModalCtrl = false;

      this.sale = { ...this.sale, sale_ticket_info: ticket, store: this.store };

      if (ticket.ticket_status === 2) {
        SAVE_LOCAL_DB_DATA("sale-details", this.sale);
        this.$router.push({ name: "PosPayment" });
      }
    },
    async fetchTickets() {
      this.isLoading = true;
      let u = await this.$store.dispatch("FETCH_UNPAID_SALES", this.store);
      const res = this.$store.getters["GET_SALE_RES"];
      // (res, "kerereret");
      this.$store.commit("SET_UP_SALE_RES", null);

      if (res && res.status) {
        const tickets = res.data || [];
        this.tickets = tickets.filter((ticket) => {
          return ticket.ticket_name !== "";
        });
      } else {
        eventBus.$emit("trac-alert", {
          message: "There are currently no tickets",
        });
      }
      this.isLoading = false;
    },
    async fetchAllProducts() {
      await this.$store.dispatch("FETCH_ALL_PRODUCTS");
      const res = this.$store.getters["GET_ALL_PRODUCT"];
      if (res.status) {
        const data = res.items || [];
        this.sale.products = this.sale.products ? this.sale.products : [];

        this.sale.products = data.map((product) => {
          if (product.variation) {
            product.varieties = product.varieties.map((va) => {
              return { ...va, initial_price: va.price, quantity: 0 };
            });
            return product;
          }
          return { ...product, initial_price: product.price, quantity: 0 };
        });

        // Remove products that are sold out
        this.sale.products = this.sale.products.filter((prod) => {
          if (prod.variation) {
            prod.varieties = prod.varieties.filter(
              (va) =>
                (va.manage_stock && this.getSpecificStore(va).stock > 0) ||
                !va.manage_stock
            );
            return prod;
          } else {
            return (
              (prod.manage_stock && this.getSpecificStore(prod).stock > 0) ||
              !prod.manage_stock
            );
          }
        });
      } else {
        eventBus.$emit("trac-alert", {
          message: res.message || "Error: Error with network.",
        });
      }
    },
    async fetchAllCustomers() {
      // Fetch all customers
      await this.$store.dispatch("FETCH_ALL_CUSTOMERS");
      const res = this.$store.getters["GET_ALL_CUSTOMERS"];

      if (res.status) {
        const customersData = res.data.items || [];

        this.customers = customersData.map((customer) => {
          return {
            ...customer,
            customerFullName:
              (customer.first_name || "") + " " + (customer.last_name || ""),
          };
        });

        // this.filteredCustomers = this.customers;
        this.filteredCustomers = this.customers.slice(0, 30);
      }
    },
    setupPayload() {
      return {
        business_id: GET_USER_BUSINESS_ID(),
        // discount_type: this.sale.totalDiscount.discount_type,
        discount: this.totalPlaceholder - this.total,
        description: "",
        payment_method: "cash",
        sale_agent: GET_USER_DATA().name,
        store_name: this.sale.store.name,
        store_id: this.sale.store._id,
        total_price: this.total,
        amount_tendered: 0,
        balance: 0,
        customer_name: this.salectedCustomer || "",
        items: this.selectedProducts.map((item) => {
          return {
            _id: item._id,
            name: item.title,
            quantity: item.quantity || item.count,
            price: item.quickSale
              ? item.quickSaleTotal
              : item.price * item.quantity,
            discount: item.quickSale ? 0 : item.initial_price - item.price,
            unit_price: item.quickSale ? item.quickSaleTotal : item.price,
          };
        }),
      };
    },
    async saveTicket(ticketName) {
      this.isLoading = true;

      this.sale.store = this.store;

      if (this.sale._id) {
        const payload = {
          saleID: this.sale._id,
          payment_method: "cash",
          body: {
            ticket_status: 1,
            ticket_name: ticketName,
            amount_tendered: 0,
            customer_name: this.salectedCustomer || "",
            customer_email: this.sale.customer ? this.sale.customer.email : "",
          },
        };
        // console.log(JSON.stringify(payload));
        
        await this.$store.dispatch("UPDATE_SALE", payload);
      } else {
        const payload = {
          ...this.setupPayload(),
          ticket_status: 1,
          ticket_name: ticketName,
        };

        // console.log(JSON.stringify(payload));
        await this.$store.dispatch("MAKE_SALE", payload);
      }
      const res = this.$store.getters["GET_SALE_RES"];

      if (res.status) {
        this.clearCart();
        this.ticketModalCtrl = false;
        this.saveTicketName = "";
      } else {
        eventBus.$emit("trac-alert", { message: res.message });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.shadow-toggle {
}

.shadow-toggle:hover {
  /* box-shadow: 0px 0px 20px rgba(22, 120, 176, 0.12); */
}

.dashboard-hold {
  grid-template-columns: 1fr;
}

@media screen and (min-width: 640px) {
  .dashboard-hold {
    grid-template-columns: 1fr 400px;
  }
}

.disable-scrollbars::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Chrome/Safari/Webkit */
  display: none;
}

.disable-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
</style>
